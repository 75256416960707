import { useState } from "react";


const BankModal = (props) => {

  const [earned, setEarned] = useState(false);

  return <div className="storeDiv">
    <div className="textBox">
      <div style={{ borderBottom: "3px solid", padding: "0", marginBottom: "5px" }}>
        <span className="text-box-title">OFFICE</span>
      </div>
      Go to work and earn real KAS for it.
      <p>Sorry, but at the end of the day, you need to give your KAS back to the office. But you can play around for FREE and create some super fast BlockDAG transactions.</p>

      <p>
        Click here, to send 1 KAS from OFFICE to your wallet.<br />
        <button className="text-box-btn" onClick={props.onClick} disabled={earned}>EARN 1 KAS</button>
        <button className="leave-btn" onClick={props.onLeave}>Leave</button>
      </p>
    </div>
  </div>
}

export default BankModal;