const StartScreen = (props) => {
    return <div className="storeDiv">
        <div className="start-screen">
            <div className="start-screen-header">Introduction to High Kaspacity</div>
            <div className="start-screen-body">
                <p>
                    Welcome to High Kaspacity, a demonstration of Kaspa BlockDAG tech - where you can experience the speed of $KAS in a fun way!
                    Kaspa Currency enables near-instant transactions from one wallet to another.
                </p><p>
                    In this demonstrator you get a personal wallet with a starting balance of 0 $KAS.
                    To switch between the buildings you can swipe, click on the buildings or use the arrow keys.
                    Head to the OFFICE to earn 1 $KAS.
                    Once you receive your 1 $KAS, you can spend $KAS by entering the shopping mall or the restaurant.
                </p>
                <p>
                    Every transaction is shown in a special window, where you can track the steps from creation to confirmation.
                    If you don't use your wallet for more than 5 minutes, all $KAS will be sent back to the BANK.
                </p>
            </div>

            <button className="start-screen-btn" onClick={() => props.onClick()}>Let's go!</button>
        </div>
    </div>
}

export default StartScreen;