export async function getWallet() {
    return await fetch("https://kaspagames.org/kaspacity/api/balance").then((res) =>
        res.json().then((d) => d))
}

export async function getBalance(addr) {
    return await fetch(`https://kaspagames.org/nodeapi/addresses/${addr}/balance`).then((res) =>
        res.json().then((d) => d.balance))
}

export async function getGameWallets() {
    return await fetch("https://kaspagames.org/kaspacity/api/gamewallets").then((res) =>
        res.json().then((d) => d))
}

export async function createTransaction(from, to, amount) {
    return await fetch("https://kaspagames.org/kaspacity/api/tx",
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ from, to, amount })
        }).then((res) => res.text().then((t) => t))
}

export async function getBlockdagInfo() {
    return await fetch("https://kaspagames.org/nodeapi/info/blockdag")
        .then((res) => res.json().then((d) => d))
}

export async function getBlocks(startHash) {
    return await fetch(`https://kaspagames.org/nodeapi/blocks?lowHash=${startHash}&includeBlocks=true&includeTransactions=false`)
        .then((res) => res.json().then((d) => d))
}