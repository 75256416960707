const DonateScreen = (props) => {
    return <div className="storeDiv">
        <div className="start-screen">
            <div className="start-screen-header"><font color="red">♥</font> DONATION <font color="red">♥</font></div>
            <div className="start-screen-body">
                <p>
                    Thanks to everyone contributing to this demonstrator. The demonstrator is made by Rob (@lAmeR11010) and Eyals girlfriend (@eyaly).<br />
                    Special thanks goes also to Wolfie who is sponsoring the domain kaspagames.org.
                </p>
                <p>
                    If you would like to donate, we really appreciate that. Here is our address:<br /><br />
                    <a className="start-screen-link" href="https://explorer.kaspa.org/addresses/kaspa:qrkz3xf72q8hfrdx3xwr368dlgydu3gwre7htjtamhcdgn9ga5p46pvcw8lle" target="_blank"><code>kaspa:qrkz3xf72q8hfrdx3xwr368dlgydu3gwre7htjtamhcdgn9ga5p46pvcw8lle</code></a>
                </p>
            </div>

            <button className="start-screen-btn" onClick={() => props.onClick()}>Close</button>
        </div>
    </div>
}

export default DonateScreen;