

const SaloonModal = (props) => {
  return <div className="storeDiv">
    <div className="textBox">
      <div style={{ borderBottom: "3px solid", padding: "0", marginBottom: "5px" }}>
        <span className="text-box-title">RESTAURANT</span>
      </div>
      Hello. You seem to be hungry. We are already closing, but if you want I can make a PIZZA for <b>0.5 KAS</b>.
      <p>
        {(props.userBalance || 0) < 2000000 && <p><br /><br />
          <font color="red">You don't have enough KAS to buy something...<br />Go to the OFFICE to get some more KAS.</font>
        </p>}

        <button className="text-box-btn" onClick={props.onClick} disabled={(props.userBalance || 0) < 2}>🍕 BUY PIZZA 🍕</button>
        <button className="leave-btn" onClick={props.onLeave}>Leave</button>
      </p>
    </div>

  </div>
}

export default SaloonModal;