import { RiCheckboxLine, RiCheckboxBlankLine } from 'react-icons/ri'
import { FaSpinner } from 'react-icons/fa'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { BiCircle } from 'react-icons/bi'
import { GoLinkExternal } from 'react-icons/go'
import { useEffect, useState } from 'react'

const TxModal = (props) => {


  const ListSymbol = (props) => {
    if (props.step < props.current) {
      return <div className="textbox-row-dot"><IoIosCheckmarkCircle className="done" /></div>
    } else if (props.current == props.step) {
      return <div className="textbox-row-dot"><FaSpinner className="spin" /></div>
    } else {
      return <div className="textbox-row-dot"><BiCircle /></div>
    }
  }

  return <div className="storeDiv">
    <div className="textBox">
      <div style={{ borderBottom: "3px solid", padding: "0", marginBottom: "1rem" }}>
        <span className="text-box-title">TRANSACTION</span>
      </div>
      Alright. We are going to start a new transaction (TX) now.

      <div className="textbox-row" style={{ marginTop: "1.6rem" }}>
        <ListSymbol step={1} current={props.step} />
        <div className="textbox-row-content">TX creation</div>
      </div>

      <div className="textbox-row" style={{ paddingLeft: "30px" }}>
        {(props.txId && <a href={`https://explorer.kaspa.org/txs/${props.txId}`} target="_blank">{`${props.txId.substring(0, 8)}...${props.txId.substring(56)}`} <GoLinkExternal /></a>) || "..."}
      </div>

      <div className="textbox-row">
        <ListSymbol step={2} current={props.step} />
        <div className="textbox-row-content">TX in mempool</div>
      </div>
      <div className="textbox-row">
        <ListSymbol step={3} current={props.step} />
        <div className="textbox-row-content">TX added to block</div>
      </div>
      <div className="textbox-row" style={{ paddingLeft: "30px" }}>
        {(props.blockId && <a href={`https://explorer.kaspa.org/blocks/${props.blockId}`} target="_blank">{`${props.blockId.substring(0, 8)}...${props.blockId.substring(56)}`} <GoLinkExternal /></a>) || "..."}
      </div>
      <div className="textbox-row">
        <ListSymbol step={4} current={props.step} />
        <div className="textbox-row-content">{(props.confirmations && Math.min(props.confirmations, 10)) || 0}/10 confirmations</div>
      </div>
      <div className="textbox-row">
          <button className="leave-btn" onClick={props.onLeave}>Close</button>
      </div>
    </div>
  </div>

}

export default TxModal;