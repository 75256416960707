import { useEffect, useRef, useState } from 'react';
import './App.css';
import { getWallet, getGameWallets, getBlockdagInfo, createTransaction, getBlocks } from './kaspa-client'
import StoreModal from './StoreModal'
import SaloonModal from './SaloonModal'
import BankModal from './BankModal'
import TxModal from './TxModal'
import StartScreen from './StartScreen'
import { BiDonateHeart } from 'react-icons/bi'
import DonateScreen from './DonateScreen'


let touchstartX = 0
let touchendX = 0
const isTxId = /[0-9a-f]{64}$/;
const sleep = ms => new Promise(r => setTimeout(r, ms));





const App = () => {

  const [myWallet, setMyWallet] = useState(null);
  const [gameWallets, setGameWallets] = useState({});
  const [modalView, setModalView] = useState(null);
  const [showStartscreen, setShowStartscreen] = useState(true);
  const [showDonate, setShowDonate] = useState(false);

  const [showTxModal, setShowTxModal] = useState(null)
  const [txModalClosed, setTxModalClosed] = useState(false)

  const [viewOffset, setViewOffset] = useState(0);

  const showTxModalRef = useRef()
  const viewOffsetRef = useRef()
  showTxModalRef.current = showTxModal
  viewOffsetRef.current = viewOffset

  const calcOffset = (fOffset) => {
    setViewOffset(fOffset)
    const w = document.getElementById("front").offsetWidth
    let frontOffset = 0;

    if (w >= 600) {
      fOffset = fOffset * 600 / w
    }

    document.getElementById("front").style.left = `${w / 2.3 * fOffset + 50}px`
    document.getElementById("fix2").style.left = `${w / 6 * fOffset - w / 6}px`
    document.getElementById("fix3").style.left = `${w / 9 * fOffset - w / 9}px`
  }

  const onClickBuilding1 = (e) => {
    calcOffset(1)
  }
  const onClickBuilding2 = (e) => {
    calcOffset(0)
  }
  const onClickBuilding3 = (e) => {
    calcOffset(-1)
  }

  function checkDirection() {
    if (touchendX < touchstartX && (touchstartX - touchendX) > 100) {
      console.log(viewOffsetRef.current)
      calcOffset(Math.max(Math.round(viewOffsetRef.current - 1), -1))
    }
    if (touchendX > touchstartX && (- touchstartX + touchendX) > 100) {
      console.log(viewOffsetRef.current)
      calcOffset(Math.min(Math.round(viewOffsetRef.current + 1), 1))
    }
  }

  const startTouch = (e) => {
    console.log("start")
    touchstartX = e.changedTouches[0].screenX
  }

  const endTouch = e => {
    console.log(e)
    touchendX = e.changedTouches[0].screenX
    checkDirection()
  }

  const keydown = e => {
    if (e.code === "ArrowLeft") calcOffset(Math.min(Math.round(viewOffsetRef.current + 1), 1))
    else if (e.code === "ArrowRight") calcOffset(Math.max(Math.round(viewOffsetRef.current - 1), -1))
  }


  useEffect(() => {
    document.addEventListener('touchstart', startTouch)
    document.addEventListener('touchend', endTouch)
    document.addEventListener('keydown', keydown);

    setShowStartscreen(true);
    // onresize();
    // zoomOutMobile();

    getWallet().then((r) => {
      setMyWallet(r)
    })
    const inv1 = setInterval(async () => {
      getWallet().then((r) => setMyWallet(r))
    }, 2000);
    getGameWallets().then((r) => {
      setGameWallets(r)
    })

    const inv2 = setInterval(async () => {
      getGameWallets().then((r) => {
        setGameWallets(r)
      })
    }, 2000);

    calcOffset(0);

    return () => {
      clearInterval(inv1)
      clearInterval(inv2)

      document.removeEventListener('touchstart', startTouch)
      document.removeEventListener('touchend', endTouch)
      document.removeEventListener('keydown', keydown)
    }

  }, []);

  const startTx = (from, to, amount) => {
    // start TRANSACTION!
    setTxModalClosed(false);
    setShowTxModal({
      'txId': "",
      'blockId': "",
      'step': 1
    })
    const dagInfo = getBlockdagInfo().then((info) => info);

    createTransaction(from, to, amount).then(async (txId) => {
      console.log("txId", txId)
      if (isTxId.exec(txId)) {
        setShowTxModal({
          'txId': txId,
          'blockId': "",
          'step': 2
        })

        setShowTxModal({
          'txId': txId,
          'blockId': "",
          'step': 3
        })

        checkTxInBlocks(txId, (await dagInfo).tipHashes[0]).then(
          (blockId) => {
            if (blockId === null) {
              console.log(`Block for TX (${txId}) not found..`)
            } else {
              console.log(`Block found: ${blockId}`)
              setShowTxModal({
                'txId': txId,
                'blockId': blockId,
                'step': 4
              })
              // paperRoll.txSeq = 3
              // paperRoll.lastBlockId = blockId

              const sound = new Audio("./assets/cash.mp3")
              sound.volume = 0.4
              sound.play()

              checkConfirmations(blockId, 10).then((total) => {
                // paperRoll.txSeq = 4
                // boy.freeze = false
                // paperRoll.freeze = false
                console.log(`Confirmations in total: ${total}`)
                const sound = new Audio("./assets/bye.mp3")
                sound.volume = 0.4
                sound.play()
                setShowTxModal({
                  'txId': txId,
                  'blockId': blockId,
                  'step': 5,
                  'confirmations': total
                })
                setTimeout(() => {
                  setShowTxModal(null)
                }, 400)

              })

            }

          }
        )
      } else {
        setShowTxModal(null)
      }

    }).catch(() => {
      setShowTxModal(null)
    })
  }
  const checkConfirmations = async (startBlock, confirmations) => {
    console.log("Count confirmations now.")
    for (let i = 0; i < confirmations * 3; i++) {
      let cntConfirmations = 0
      await getBlocks(startBlock).then((blocksResp) => {
        // console.log("new response with", blocksResp)

        for (const b of blocksResp.blocks) {
          cntConfirmations += 1
        }

        setShowTxModal({ ...showTxModalRef.current, confirmations: cntConfirmations })

      })

      if (cntConfirmations >= confirmations)
        return cntConfirmations

      console.log(`${cntConfirmations} confirmations. Try again.`)
      await sleep(1000);
    }
    return null
  }

  const checkTxInBlocks = async (txId, startBlock) => {
    let blockId = undefined
    let lastCheckedBlock = startBlock
    for (let i = 0; i < 10 * 4; i++) {
      blockId = await getBlocks(lastCheckedBlock).then((blocksResp) => {
        console.log("new response with", blocksResp)
        for (const b of blocksResp.blocks) {
          for (const blockTxId of b.verboseData.transactionIds) {
            if (blockTxId == txId) {
              return b.verboseData.hash
            }
          }
          lastCheckedBlock = b.verboseData.hash
        }

      })
      if (!!blockId) {
        return blockId
      }

      console.log(`not found. try again with ${lastCheckedBlock}`)
      await sleep(300);
    }
    return null
  }

  return <div id="scaler">
    <div id="app" className="App">
      <div id="donate"><span onClick={() => setShowDonate(true)}><BiDonateHeart /></span></div>
      <p hidden={showStartscreen} className="my-address" onClick={() => { window.open(`https://explorer.kaspa.org/addresses/${myWallet.address}`, "_blank"); }}>
        Your wallet: <span>{myWallet && myWallet.balance / 100000000 || "0"} KAS</span><br />
        {(myWallet && `${myWallet.address.substring(0, 16)}...${myWallet.address.substring(54)}`) || "-"}</p>
      {/* <div id="kaspacity">High KaspaCity</div> */}
      <img className="fix3" id="fix3" src="/kaspacity/images/3.png" />
      <img className="fix2" id="fix2" src="/kaspacity/images/2.png" />
      <div className="front" id="front">
        {/* <img className="fix1" hidden id="fix1" src="/kaspacity/images/1.png" onClick={onClickBuilding}/> */}
        <div id="b1">
          <p className="building-address" onClick={() => { window.open(`https://explorer.kaspa.org/addresses/${gameWallets.STORE.address}`, "_blank"); }}>
            <span>{gameWallets.STORE && gameWallets.STORE.balance / 100000000 || "0"} KAS</span>
            {viewOffset === 1 && <><br />
              {(gameWallets.STORE && `${gameWallets.STORE.address.substring(0, 12)}...${gameWallets.STORE.address.substring(61)}`) || "-"}</>}</p>
          {viewOffset === 1 && <div className="enter-button-div"><button id="enter-office" onClick={() => setModalView("store")}>ENTER</button></div>}
          <img src="/kaspacity/images/b1.png" onClick={onClickBuilding1} />
          <p className="building-title" viewOffset={viewOffset} activate={viewOffset === 1 ? "focus" : "none"}>SHOPPING MALL</p>
        </div>

        <div id="b2" style={viewOffset === 0 ? { "maxHeight": "90%" } : {}}>
          <p className="building-address" onClick={() => { window.open(`https://explorer.kaspa.org/addresses/${gameWallets.BANK.address}`, "_blank"); }}>
            <span>{gameWallets.BANK && Math.round(gameWallets.BANK.balance / 100000000) || "0"} KAS</span>
            {viewOffset === 0 && <><br />
              {(gameWallets.BANK && `${gameWallets.BANK.address.substring(0, 12)}...${gameWallets.BANK.address.substring(61)}`) || "-"}</>}</p>
          {viewOffset === 0 && <div className="enter-button-div"><button id="enter-office" onClick={() => setModalView("bank")}>ENTER</button></div>}
          <img src="/kaspacity/images/b2.png" onClick={onClickBuilding2} />
          <p className="building-title" viewOffset={viewOffset} activate={viewOffset === 0 ? "focus" : "none"}>OFFICE</p>
        </div>

        <div id="b3">
          <p className="building-address" onClick={() => { window.open(`https://explorer.kaspa.org/addresses/${gameWallets.SALOON.address}`, "_blank"); }}>
            <span>{gameWallets.SALOON && gameWallets.SALOON.balance / 100000000 || "0"} KAS</span>

            {viewOffset === -1 && <><br />
              {(gameWallets.SALOON && `${gameWallets.SALOON.address.substring(0, 12)}...${gameWallets.SALOON.address.substring(61)}`) || "-"}</>}</p>
          {viewOffset === -1 && <div className="enter-button-div"><button id="enter-office" onClick={() => setModalView("saloon")} >ENTER</button></div>}
          <img src="/kaspacity/images/b3.png" onClick={onClickBuilding3} />
          <p className="building-title" viewOffset={viewOffset} activate={viewOffset === -1 ? "focus" : "none"}>RESTAURANT</p>
        </div>

        {(!showTxModal || txModalClosed) && <div className="modals">
          {modalView === "store" ? <StoreModal onLeave={() => setModalView(null)} onClick={() => startTx("my", "STORE", 60000000)} userBalance={(myWallet && myWallet.balance) || 0} /> : <></>}
          {modalView === "saloon" ? <SaloonModal onLeave={() => setModalView(null)} onClick={() => startTx("my", "SALOON", 50000000)} userBalance={(myWallet && myWallet.balance) || 0} /> : <></>}
          {modalView === "bank" ? <BankModal onLeave={() => setModalView(null)} onClick={() => startTx("BANK", "my", 300000000)} /> : <></>}
        </div>}

        {!txModalClosed && !!showTxModal && <TxModal txId={showTxModal.txId} blockId={showTxModal.blockId} step={showTxModal.step} confirmations={showTxModal.confirmations || 0}
          onLeave={() => setTxModalClosed(true)} />}

        {!!showStartscreen && <StartScreen onClick={() => setShowStartscreen(false)} />}
        {!!showDonate && <DonateScreen onClick={() => setShowDonate(false)} />}
      </div>
    </div>
  </div>
}


export default App;
