

const StoreModal = (props) => {
  return <div className="storeDiv">
    <div className="textBox">
      <div style={{ borderBottom: "3px solid", padding: "0", marginBottom: "5px" }}>
        <span className="text-box-title">SHOPPING MALL</span>
      </div>
      Here you can buy a T-SHIRT for <b>0.6 KAS</b>. Just click the button "BUY" and you will see, how fast the TX will be processed.

      {(props.userBalance || 0) < 10000000 && <p><br /><br />
        <font color="red">You don't have enough KAS to buy something...<br />Go to the OFFICE to get some more KAS.</font>
      </p>}

      <p>
        <button className="text-box-btn" onClick={props.onClick} disabled={(props.userBalance || 0) < 1}>Buy T-SHIRT</button>
        <button className="leave-btn" onClick={props.onLeave}>Leave</button>
      </p>
    </div>

  </div>

}

export default StoreModal;